<template>
  <vx-card title="Debit Note">
    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-3">
        <vs-divider />
        <span class="font-bold">Supplier Returns</span>
        <div style="max-height: 250px; overflow: auto">
          <vs-table stripe :data="supllierReturns">
            <template slot="thead">
              <vs-th> SR Number </vs-th>
              <vs-th> SR Type </vs-th>
              <vs-th> PO Number Reference </vs-th>
              <vs-th> GRR Code </vs-th>
              <vs-th> Suppplier </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  {{ tr.supplier_returns.code }}
                </vs-td>

                <vs-td>
                  {{ tr.supplier_returns.ref_status }}
                </vs-td>

                <vs-td>
                  {{ tr.purchase_order ? tr.purchase_order.Code : "" }}
                </vs-td>

                <vs-td>
                  {{ tr.supplier_returns.grr_code }}
                </vs-td>

                <vs-td>
                  Code : {{ tr.supplier_returns.supplier_code }} <br />
                  Name : {{ tr.supplier_returns.supplier_name }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
      <vs-divider />
      <form-header
        v-if="responseData.debitNotes"
        @updateModel="updateHeaderModels"
        @updateFile="updateFile"
        @deleteAttachment="deleteAttachment"
        @addTaxInvoiceReferenceOption="addTaxInvoiceReferenceOption"
        :isSubmit="isSubmit"
        :settlementMethodOptions="settlementMethodOptions"
        :header="responseData.debitNotes"
        :attachment="responseData.attachment"
        :taxInvoiceReferenceOptions="taxInvoiceReferenceOptions"
      />
      <vs-divider />
      <form-details
        v-if="this.supplierReturnDetails.length > 0"
        :supplierReturnDetails="supplierReturnDetails"
        :lines="responseData.lines"
        :deletedLines="deletedLines"
        :tax="responseData.tax"
        @updateModel="updateDetailModels"
        :isSubmit="isSubmit"
        @reload="getDetail"
      />
      <vs-divider />
      <div class="flex gap-3">
        <vs-button @click="submitEdit">Save</vs-button>
        <vs-button @click="navigateBack" color="danger">Close</vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>
import form_header from "./form_header.vue";
import form_details from "./form_details.vue";
import moment from "moment";
export default {
  components: {
    "form-header": form_header,
    "form-details": form_details,
  },
  data() {
    return {
      responseData: {},
      supllierReturns: [],
      supplierReturnDetails: [],
      settlementMethodOptions: [],
      taxInvoiceReferenceOptions: [],
      deletedLines: [],
      supplierSettlement: null,
      dn_id: 0,
      params: {
        search: "",
        length: 20,
        page: 1,
        order: "desc",
        sort: "id",
      },
      formHeaderModels: {
        cnNumber: "",
        cnDate: "",
        trNumber: "",
        trDate: "",
        settlementMethod: {},
        dnNumber: "",
        postingDate: "",
        note: "",
        taxInvoiceReference: {},
        taxInvoiceReferenceCode: "",
        taxInvoiceReferenceRefDate: "",
      },
      formDetailModels: [],
      isSubmit: false,
      file: null,
      sr_ids: [],
      pr_ids: [],
      isReopenSuppplierReturn: false,
      debitNote: null,
      isNotUsingHandover: false,
    };
  },
  mounted() {
    const param = this.$router.currentRoute.params;
    const id = atob(param.id);
    this.dn_id = parseInt(id);
    console.log(this.dn_id);
    this.getDetail();
  },
  methods: {
    async getSupplierReturns(params) {
      this.$vs.loading();
      params = {
        ...params,
        pr_status: 11,
        sr_status: 8,
        ids: [...this.sr_ids, ...this.pr_ids],
        dn_number: this.responseData.debitNotes.Code,
        is_edit: true,
      };
      try {
        const response = await this.$http.get(
          "/api/v1/debit-note/supplier-return",
          {
            params,
          }
        );
        this.supllierReturns = response.data.records;
        console.log("supllierReturns", this.supllierReturns);
        // this.supplierSettlement = response.data.records[0].supplier_settlement;
        // console.log("stlmnt", this.supplierSettlement);
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        throw e;
      }
    },
    async getSupplierReturnDetails() {
      this.$vs.loading();
      const params = {
        pr_ids: this.pr_ids,
        sr_ids: this.sr_ids,
        is_edit: true,
        is_not_using_handover: this.isNotUsingHandover,
      };
      try {
        const response = await this.$http.get(
          "/api/v1/debit-note/supplier-return/detail",
          {
            params,
          }
        );
        this.supplierReturnDetails = response.data;
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        throw e;
      }
    },
    async getSupplierSettlementMethods() {
      this.$vs.loading();
      try {
        const response = await this.$http.get(
          "/api/v1/supplier-settlement-method"
        );
        this.settlementMethodOptions = response.data;
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        throw e;
      }
    },
    async getDeletedDebitNoteLine() {
      this.$vs.loading();
      try {
        const response = await this.$http.get(
          `/api/v1/debit-note/return/deleted-line/${this.dn_id}`
        );
        this.$vs.loading.close();
        if (response.code > 299) {
          this.$vs.notify({
            title: "Error",
            text: response.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          return;
        }
        this.deletedLines = response.data;
        this.deletedLines.forEach((line) => {
          console.log("deleted line", line);
          if (line.PurchaseReturnID && line.PurchaseReturnID > 0) {
            this.pr_ids.push(line.PurchaseReturnID);
          } else {
            this.sr_ids.push(line.SupplierReturnID);
          }
        });

        this.pr_ids = [...new Set(this.pr_ids)];
        this.sr_ids = [...new Set(this.sr_ids)];
        console.log(this.deletedLines);
      } catch (e) {
        this.$vs.loading.close();
        throw e;
      }
    },
    async getTaxInvoiceReference(purchaseInvoiceIDS = []) {
      this.$vs.loading();
      const params = {
        supplier_id: this.supllierReturns[0].supplier_returns.supplier_id,
        debit_note_id: this.dn_id,
        ids: purchaseInvoiceIDS,
      };
      // if (this.supllierReturns[0].supplier_returns.ref_status === "with ref") {
      //   params.purchase_order_id = [this.supllierReturns[0].purchase_order.ID];
      // }
      try {
        const response = await this.$http.get(
          "/api/v1/debit-note/return/tax-invoice-reference",
          {
            params,
          }
        );
        this.$vs.loading.close();
        if (response.code > 299) {
          this.$vs.notify({
            title: "Error",
            text: response.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          return;
        }
        this.taxInvoiceReferenceOptions = response.data ? response.data : [];
      } catch (e) {
        this.$vs.loading.close();
        throw e;
      }
    },
    async getDebitNote() {
      this.$vs.loading();
      try {
        const response = await this.$http.get(
          `/api/v1/debit-note/return/detail`,
          {
            params: {
              id: this.dn_id,
            },
          }
        );
        this.responseData = response.data;
        this.responseData.lines.forEach((line) => {
          if (line.PurchaseReturnID && line.PurchaseReturnID > 0) {
            this.pr_ids.push(line.PurchaseReturnID);
          } else {
            this.sr_ids.push(line.SupplierReturnID);
          }
        });

        if (
          this.responseData.lines[0].PurchaseReturnOutboundHandoverLineID == 0
        ) {
          this.isNotUsingHandover = true;
        }

        console.log("pr_ids", this.pr_ids);
        console.log("sr_ids", this.sr_ids);

        this.pr_ids = [...new Set(this.pr_ids)];
        this.sr_ids = [...new Set(this.sr_ids)];
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        throw e;
      }
    },
    async getDetail() {
      try {
        await this.getDebitNote();
        await this.getDeletedDebitNoteLine();
        await this.getSupplierReturns(this.params);
        await this.getSupplierSettlementMethods();
        await this.getSupplierReturnDetails();
        await this.getTaxInvoiceReference(
          this.responseData.debitNotes.TaxInvoiceReferenceID
            ? [this.responseData.debitNotes.TaxInvoiceReferenceID]
            : []
        );
      } catch (e) {
        console.log(e);
        this.$vs.notify({
          title: "Error",
          text: e.message,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }
    },
    updateDetailModels(value) {
      this.formDetailModels = value;
    },
    updateHeaderModels(value) {
      this.formHeaderModels = value;
    },
    updateFile(value) {
      this.file = value;
    },
    deleteAttachment(id, index) {
      this.$vs.loading();
      var form = new FormData();
      form.append("id", id);
      this.$http
        .post("/api/v1/debit-note/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.responseData.attachment.splice(index, 1);
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    resetIsSubmit() {
      this.isSubmit = false;
    },
    validateTotal(aggregate, remaining_tax_invoice, tax_invoice_reference) {
      if (
        !tax_invoice_reference ||
        Object.keys(tax_invoice_reference).length === 0
      ) {
        return true;
      }

      if (aggregate.total > remaining_tax_invoice) {
        this.$vs.notify({
          title: "Debit Note",
          text: "Debit note total value cannot be greater than remaining of tax invoice reference",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
          time: 4000,
        });
        return false;
      }
      return true;
    },
    validate() {
      let isValid = true;
      // if (this.formHeaderModels.cnNumber === "") {
      //   isValid = false;
      //   this.$vs.notify({
      //     title: "Debit Note",
      //     text: "Supplier credit note number is required",
      //     color: "danger",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return isValid;
      // }

      // if (this.formHeaderModels.cnDate === "") {
      //   isValid = false;
      //   this.$vs.notify({
      //     title: "Debit Note",
      //     text: "Supplier credit note Date is required",
      //     color: "danger",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return isValid;
      // }

      if (
        !this.formHeaderModels.trDate ||
        this.formHeaderModels.trDate === ""
      ) {
        isValid = false;
        this.$vs.notify({
          title: "Debit Note",
          text: "Tax return Date is required",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return isValid;
      }

      if (
        !this.formHeaderModels.settlementMethod ||
        Object.keys(this.formHeaderModels.settlementMethod).length === 0 ||
        this.formHeaderModels.settlementMethod === ""
      ) {
        isValid = false;
        this.$vs.notify({
          title: "Debit Note",
          text: "Settlement method is required",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return isValid;
      }

      // if (
      //   !this.formHeaderModels.taxInvoiceReference ||
      //   Object.keys(this.formHeaderModels.taxInvoiceReference).length === 0 ||
      //   this.formHeaderModels.taxInvoiceReference === ""
      // ) {
      //   isValid = false;
      //   this.$vs.notify({
      //     title: "Debit Note",
      //     text: "Tax invoice reference is required",
      //     color: "danger",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return isValid;
      // }

      if (this.formHeaderModels.dnNumber === "") {
        isValid = false;
        this.$vs.notify({
          title: "Debit Note",
          text: "Debit note number is required",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return isValid;
      }

      if (
        !this.formHeaderModels.trDate ||
        this.formHeaderModels.trDate === ""
      ) {
        isValid = false;
        this.$vs.notify({
          title: "Debit Note",
          text: "Tax return Date is required",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return isValid;
      }

      if (
        !this.formHeaderModels.taxInvoiceReference ||
        Object.keys(this.formHeaderModels.taxInvoiceReference).length === 0
      ) {
        if (this.formHeaderModels.taxInvoiceReferenceCode === "") {
          isValid = false;
          this.$vs.notify({
            title: "Debit Note",
            text: "Tax invoice reference is required",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          return isValid;
        }

        if (
          !this.formHeaderModels.taxInvoiceReferenceRefDate ||
          this.formHeaderModels.taxInvoiceReferenceRefDate === ""
        ) {
          isValid = false;
          this.$vs.notify({
            title: "Debit Note",
            text: "Tax invoice reference date is required",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          return isValid;
        }
      }

      if (this.formHeaderModels.postingDate === "") {
        isValid = false;
        this.$vs.notify({
          title: "Debit Note",
          text: "Posting Date is required",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return isValid;
      }

      // if (this.formDetailModels.taxRate === 0) {
      //   isValid = false;
      //   this.$vs.notify({
      //     title: "Debit Note",
      //     text: "Tax rate are required",
      //     color: "danger",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return isValid;
      // }

      // if (this.formDetailModels.refAPInvoice === "") {
      //   isValid = false;
      //   this.$vs.notify({
      //     title: "Debit Note",
      //     text: "Reference AP Invoice is required",
      //     color: "danger",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return isValid;
      // }

      console.log("len", this.formDetailModels.details.length);
      for (let i = 0; i < this.formDetailModels.details.length; i++) {
        const qty = parseInt(
          this.formDetailModels.details[i].qty.replace(/,/g, "")
        );

        if (isNaN(qty)) {
          isValid = false;
          this.$vs.notify({
            title: "Debit Note",
            text: "Quantity is not valid",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          break;
        }

        if (qty === 0) {
          isValid = false;
          this.$vs.notify({
            title: "Debit Note",
            text: "Quantity amount must be greater than 0",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          break;
        }

        const maxQty = parseInt(
          this.formDetailModels.details[i].max_quantity.replace(/,/g, "")
        );
        if (qty > maxQty) {
          isValid = false;
          this.$vs.notify({
            title: "Debit Note",
            text: "Quantity amount is over limit",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          break;
        }

        if (
          this.formDetailModels.details[i].total === "" ||
          this.formDetailModels.details[i].total === "0"
        ) {
          isValid = false;
          this.$vs.notify({
            title: "Debit Note",
            text: "Debit note total is required",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          break;
        }

        if (
          this.formDetailModels.details[i].value === "" ||
          this.formDetailModels.details[i].value === "0"
        ) {
          isValid = false;
          this.$vs.notify({
            title: "Debit Note",
            text: "Debit note value is required",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          break;
        }
      }
      return isValid;
    },
    navigateBack() {
      this.$router.push({
        name: "dn-with-or-without-po-ref",
        params: { tab: 1 },
      });
    },
    submitEdit() {
      this.isSubmit = true;

      this.$nextTick(() => {
        console.log(this.formDetailModels);
        console.log(this.formHeaderModels);
        this.isSubmit = false;

        if (!this.validate()) {
          return;
        }

        const aggregate = this.formDetailModels.details.reduce(
          (acc, detail) => {
            acc.qty =
              parseFloat(acc.qty) + parseFloat(detail.qty.replace(/,/g, ""));
            acc.value =
              parseFloat(acc.value) +
              parseFloat(detail.value.replace(/,/g, ""));
            acc.tax =
              parseFloat(acc.tax) + parseFloat(detail.tax.replace(/,/g, ""));
            acc.total =
              parseFloat(acc.total) +
              parseFloat(detail.total.replace(/,/g, ""));

            return acc;
          },
          {
            qty: 0,
            value: 0,
            tax: 0,
            total: 0,
          }
        );

        if (
          !this.validateTotal(
            aggregate,
            this.formHeaderModels.taxInvoiceReference &&
              Object.keys(this.formHeaderModels.taxInvoiceReference).length > 0
              ? this.formHeaderModels.taxInvoiceReference.remaining
              : 0,
            this.formHeaderModels.taxInvoiceReference
          )
        ) {
          return;
        }
        this.$vs.loading();
        const header = {
          // ...this.formHeaderModels,
          cnNumber: this.formHeaderModels.cnNumber,
          cnDate: this.formHeaderModels.cnDate,
          trNumber: this.formHeaderModels.trNumber,
          trDate: this.formHeaderModels.trDate,
          settlementMethod: this.formHeaderModels.settlementMethod,
          postingDate: this.formHeaderModels.postingDate,
          note: this.formHeaderModels.note,
          taxInvoiceReferenceCode:
            this.formHeaderModels.taxInvoiceReferenceCode,
          taxInvoiceReference: {},
          taxInvoiceReferenceRefDate:
            this.formHeaderModels.taxInvoiceReferenceRefDate,
        };

        header.cnDate = header.cnDate
          ? moment(header.cnDate).format("YYYY-MM-DD")
          : "";
        header.trDate = header.trDate
          ? moment(header.trDate).format("YYYY-MM-DD")
          : "";
        header.postingDate = moment(header.postingDate).format("YYYY-MM-DD");
        header.taxInvoiceReferenceRefDate = header.taxInvoiceReferenceRefDate
          ? moment(header.taxInvoiceReferenceRefDate).format("YYYY-MM-DD")
          : "";

        header.aggregate = aggregate;
        header.dn_id = this.dn_id;
        if (
          this.formHeaderModels.taxInvoiceReference &&
          Object.keys(this.formHeaderModels.taxInvoiceReference).length > 0
        ) {
          header.taxInvoiceReference = {
            id: this.formHeaderModels.taxInvoiceReference.id,
            value: header.aggregate.total,
          };
        }
        // header.supplierID = this.responseData[0].supplier_returns.supplier_id;
        // header.warehouseID = this.responseData[0].supplier_returns.warehouse_id;
        // const pr_ids = [];
        // const sr_ids = [];

        // this.responseData.lines.forEach((line) => {
        //   if (line.PurchaseReturnID && line.PurchaseReturnID > 0) {
        //     pr_ids.push(line.PurchaseReturnID);
        //   } else {
        //     sr_ids.push(line.SupplierReturnID);
        //   }
        // });

        // pr_ids = [...new Set(pr_ids)];
        // sr_ids = [...new Set(sr_ids)];

        const details = [];

        for (let i = 0; i < this.formDetailModels.details.length; i++) {
          console.log();
          // const reopenQty =
          //   parseFloat(this.responseData.lines[i].ReturnQuantity) -
          //   parseFloat(this.formDetailModels.details[i].qty.replace(/,/g, ""));
          // let isReopen = false;
          // if (reopenQty > 0) {
          //   isReopen = true;
          // }
          console.log(this.formDetailModels.details[i], "details from submit");
          console.log(this.supplierReturnDetails, "supplierReturnDetails from submit");

          const line = this.supplierReturnDetails.find((data) => {
            if (this.isNotUsingHandover) {
              return this.formDetailModels.details[i].prl_id
                ? data.id === this.formDetailModels.details[i].prl_id
                : data.id === this.formDetailModels.details[i].ref_id;
            } else {
              return (
                data.id ===
                this.formDetailModels.details[i]
                  .PurchaseReturnOutboundHandoverLineID
              );
            }
          });

          console.log(line, "line from submit");

          const detail = {
            id: this.formDetailModels.details[i].id,
            ref_id: this.formDetailModels.details[i].ref_id,
            prl_id: this.formDetailModels.details[i].prl_id,
            qty: parseFloat(
              this.formDetailModels.details[i].qty.replace(/,/g, "")
            ),
            total: parseFloat(
              this.formDetailModels.details[i].total.replace(/,/g, "")
            ),
            value: parseFloat(
              this.formDetailModels.details[i].value.replace(/,/g, "")
            ),
            tax: parseFloat(
              this.formDetailModels.details[i].tax.replace(/,/g, "")
            ),
            item_name: this.formDetailModels.details[i].item_name,
            grr_code: this.formDetailModels.details[i].grr_code,
            // is_reopen: isReopen,
            // reopen_qty: Math.abs(reopenQty),
            is_recover: this.formDetailModels.details[i].is_recover
              ? true
              : false,
          };

          if (line.purchase_return_id) {
            detail.purchase_return_id = line.purchase_return_id;
          } else {
            detail.supplier_return_id = line.supplier_return_id;
          }
          details.push(detail);
        }

        const detail_info = {
          ref_ap_invoice: this.formDetailModels.refAPInvoice,
          tax_rate: this.formDetailModels.taxRate,
          tax_id: this.formDetailModels.taxID,
          details,
          deleted: this.formDetailModels.deleted,
        };

        // header.reopen = this.isReopenSuppplierReturn;

        const data = {
          header,
          detail_info,
        };

        console.log(data);

        const form = new FormData();
        form.append("data", JSON.stringify(data));

        if (this.file) {
          form.append("file", this.file);
        }

        this.$http
          .put("/api/v1/debit-note/return", form)
          .then((r) => {
            this.$vs.loading.close();

            if (r.code < 299) {
              this.$vs.notify({
                title: "Debit Note",
                text: "Debit note Edited successfully",
                color: "success",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
              this.$router.push({
                name: "dn-with-or-without-po-ref",
              });
            } else {
              this.$vs.notify({
                title: "Debit Note",
                text: r.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle",
              });
            }
          })
          .catch((e) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: e.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
          });
      });
    },
    addTaxInvoiceReferenceOption(obj) {
      console.log("run dari sini");
      this.taxInvoiceReferenceOptions.push(obj);
      console.log(
        this.taxInvoiceReferenceOptions,
        "taxInvoiceReferenceOptions"
      );
    },
  },
};
</script>
